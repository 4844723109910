module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-22549453-1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/usr/src/app/www/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.3.33","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"remarkPlugins":[null],"defaultLayouts":{"posts":"/usr/src/app/www/theme/src/templates/single-post.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-prismjs","options":{"aliases":{"sh":"bash","js":"javascript"}}},{"resolve":"gatsby-remark-katex","options":{"strict":"ignore"}}]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../theme/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/","trackingId":"UA-22549453-1","postsPath":"posts","imagesPath":"images","postsPerPage":"100","mailchimpURL":"https://perceptible.us20.list-manage.com/subscribe/post?u=4b577277f9707f7a20a76ed07&amp;id=f08637f7f1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Site perceptible.fr","short_name":"perceptible","description":"Dataviz et Dashboarding blog","start_url":"/","background_color":"#673ab7","theme_color":"#673ab7","display":"standalone","icon":"images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"13ead02f7f4b579d4370dcbf76b702d7"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
