
// Imports should be placed on top of the file

// Importing Global CSS file
import "./src/styles/global.css"
import 'typeface-roboto';
import 'typeface-markazi-text';


require("./src/styles/code-theme.css")
require(`katex/dist/katex.min.css`)
//require("prismjs/plugins/line-numbers/prism-line-numbers.css")
