import React from 'react'

class CheckURL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    function setURL(id, value){
        document.getElementById(id).href = value;
        document.getElementById(id).target ="\"_blank\"";
        document.getElementById(id).textContent =  value
    }

    function isGoogle(key){
        const test = key.match("d/(.*?)/edit"); // peut etre faut il doubler les \ ?
        if (test) return test[1]
        return false
    }

    function isNotion(key) {
        const test = key.match('/www.notion.so/.*/(.*)\\?');
        if (test) return 'notion' +test[1]
        return false
    }

    const key = event.target.value;
    this.setState({value: key});
    let link;
    link = isGoogle(key)
    if (link) {setURL("reportlink","/sandbox/forms/run.html?key=" + link); return}
    link = isNotion(key)
    if (link) {setURL("reportlink","/sandbox/forms/run.html?key=" + link); return} 
    setURL("reportlink","Lien invalide");
  }

  // change code above this line
  render() {
    return (
        <div>
            <div>
                <span>
                    Coller le lien ici:
                    <input id="formID" type="text" size= "60"  onChange= {this.handleChange} />
                </span>
            </div>
            <span>
            Vous pouvez consulter votre rapport là:
            <a id = 'reportlink' href=''> lien invalide</a>
            </span>
            <br/>
        </div>
    );
  }
};

export default CheckURL;
