import Wave from "../../../../posts/mdx/wave";
import { Link } from 'gatsby';
import Barchart from "../../../../posts/mdx/barchart";
import Counter from "../../../../posts/mdx/counter";
import * as React from 'react';
export default {
  Wave,
  Link,
  Barchart,
  Counter,
  React
};